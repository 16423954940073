<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="reboque.id" disable />
          </erp-s-field>
        </e-col>

        <e-col>
          <erp-s-field
              view="lr"
              label="Descrição"
          >
            <erp-input simple-border v-model="reboque.descricao" />
          </erp-s-field>
        </e-col>

        <e-col>
          <erp-s-field
              view="lr"
              label="Placa"
          >
            <erp-input simple-border v-model="reboque.placa" />
          </erp-s-field>
        </e-col>

      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" :class="{'flex col-grow': tabActive === 'logs' || tabActive === 'arremates'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Marca/Modelo:"
                >
                  <erp-input v-model="reboque.marcaModelo" />
                </erp-s-field>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label="Custo por KM:"
                >
                  <erp-input v-model="reboque.custo" v-money="money" />
                </erp-s-field>
              </e-col>
            </e-row>

          </e-col>
          <e-col class="m-l-xxl" style="min-width: 216px; max-width: 216px">
            <foto ref="foto" :arrematante="reboque" @update="updateFoto" @toDelete="deleteFotoEvent" />
          </e-col>
        </e-row>
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 v-if="!id" class="m-t">Reboque registrado com sucesso!</h5>
    <h5 v-else class="m-t">Reboque atualizado com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  ErpLabel,
  ErpSelect,
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {date} from 'uloc-vue'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '../../../../utils/money'
import {
  find,
  newReboque,
  updateReboque,
  updateFotoReboque
} from '../../../../domain/reboque/services/index'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import Foto from '../../../arrematante/components/include/Foto'

let mockReboque = {
  id: null,
  descricao: null,
  placa: null,
  marcaModelo: null,
  custo: null,
  active: true
}

export default {
  name: 'ReboqueWindow',
  directives: {money: VMoney},
  props: ['id'],
  data () {
    return {
      money: REAL_BRL,
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      deleteFoto: false,
      reboque: JSON.parse(JSON.stringify(mockReboque))
    }
  },
  mounted () {
    this.load()
  },
  computed: {
  },
  watch: {},
  methods: {
    load () {
      this.id && find(this.id || this.reboque.id)
        .then(response => {
          let data = response.data
          this.reboque = data
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o reboque, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.reboque))

      data.custo = convertRealToMoney(data.custo)

      this.status = 'Processando dados do reboque'
      let method = data.id ? updateReboque(data.id, data) : newReboque(data)
      method
        .then(({data}) => {
          this.id = this.reboque.id = data.id
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              // this.$refs.timer.start()
              window.setTimeout(() => {
                this.loading = false
                this.success = false
                this.load()
              }, 3000)
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          }
          // Processa foto
          if (this.deleteFoto) {
            console.log('Deleta Foto')
          } else if (this.$fotoReboque) {
            this.status = 'Analisando e processando foto do reboque'
            updateFotoReboque(this.reboque.id, {data: this.$fotoReboque, filename: 'foto'})
              .then(({data}) => {
                cbSuccess()
                delete this.$fotoReboque
              })
              .catch((error) => {
                alert('Impossível enviar a foto do reboque, mas os demais dados foram processados com sucesso!')
                console.error(error)
                cbSuccess()
              })
          } else {
            cbSuccess()
          }

        })
        .catch(error => {
          console.log(error)
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    },
    deleteFotoEvent () {
      this.deleteFoto = true
    },
    updateFoto (file) {
      this.$fotoReboque = file
    }
  },
  components: {
    Foto,
    ECol,
    ERow,
    DefaultAsyncSelect,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    WindowBody,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    ErpLabel,
    ErpSelect
  }
}
</script>
